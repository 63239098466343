.App {
  text-align: center;
  position: fixed;
  left: 5%;
  top: 0;
  width: 96%;
  height: 100%;
  color: #F6C30D;
  font-weight: 500;
}

.App::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("/Landingpage.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  filter: opacity(0.2);
}

.App .Page {
  position: relative;
}

.TeamMember, .ServerList {
  text-align: center;
  display: block;
  overflow-wrap: break-word;
  margin: 1rem;
  padding: 1rem;
  width: 13.5rem;
  min-width: 13.5rem;
  height: fit-content;
  border-radius: 1rem;
  background-color: #262626BB;
  box-shadow: 5px 5px 20px -5px #000000AA;
  backdrop-filter: blur(2px);
  position: relative;
}

.FrontI.App {
  text-align: center;
  position: fixed;
  left: 5%;
  top: 0;
  width: 96%;
  height: 100%;
  color: #F6C30D;
  font-weight: 500;
}

.App::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("/Landingpage.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  filter: opacity(0.2);
}

.App .Page {
  position: relative;
}

.TeamMember, .ServerList {
  text-align: center;
  display: block;
  overflow-wrap: break-word;
  margin: 1rem;
  padding: 1rem;
  width: 13.5rem;
  min-width: 13.5rem;
  height: fit-content;
  border-radius: 1rem;
  background-color: #262626BB;
  box-shadow: 5px 5px 20px -5px #000000AA;
  backdrop-filter: blur(2px);
  position: relative;
}

.FrontItem1 {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 165px);
  justify-content: center;
  background-image: linear-gradient(to bottom, #00000000, #1A395266);
}

.FrontItem2 {
  display: flex;
  height: calc(100% - 156px);
  justify-content: center;
  background-image: linear-gradient(to bottom, #00000000, #F6C30D22);
}

.FrontItem3 {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  background-image: linear-gradient(to bottom, #00000000, #1A395266);
}

.TeamList {
  margin: auto;
  text-align: center;
  height: 100%;
  display: flex;
}

.TeamMember {
  display: flex;
  width: 30%;
  height: calc(50% - 5rem);
  margin: 4rem auto;
  color: white;
  text-align: left;
  font-size: 1rem;
}

.ServerList {
  display: flex;
  width: 20rem;
  height: 8rem;
  color: white;
  text-align: left;
  font-size: 1rem;
}

.MarketLink {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: #FEFEFE;
}

.MemberDetails {
  position: relative;
  height: 100%;
}

.TeamMember img {
  max-height: 150px;
  height: fit-content;
  border-radius: 1rem;
}

.TeamMember table {
  width: 100%;
  font-size: 0.8rem;
}

.TeamMember td:first-child {
  color: #F6C30D;
  text-align: left;
}

.TeamMember td:nth-child(2) {
  color: white;
  text-align: right;
}

.TeamMember a {
  color: white;
  cursor: pointer;
}

.MemberInfo {
  margin: 1rem;
}

.ClassBlue {
  padding: 50px;
  background-color: #1A395266;
  height: fit-content;
}

.Content {
  color: white;
  width: 50%;
  font-size: 1rem;
  margin: 1rem 25%;
  text-align: left;
}

.AssetListHeader {
  margin-top: 100px;
  margin-bottom: 20px;
  margin-left: 100px;
  font-size: 30pt;
  text-align: left;
  color: white;
  display: flex;
}

.AssetListHeader a {
  color: white;
  cursor: pointer;
}

.FrontItem2 a, .FrontItem1 a, .FrontItem3 a {
  color: #4a82af;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;

  color: #F6C30D;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
  width: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F6C30D;
  height: 50px;
  width: 50px;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: "SFCompact";
  src: url("/fonts/SF-Compact-Rounded-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SFCompactBold";
  src: url("/fonts/SF-Compact-Rounded-Bold.otf") format("opentype");
  font-weight: bold;
}

body {
  background-image: url('/background.svg');
  font-family: "SFCompact",sans-serif;
  background-color: #000;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body:before {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

body:after {
    z-index: 1;
    backdrop-filter: blur(20);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .App {
    width: 100%;
    left: 0;
  }
}

.AppLink {
  display: block;
  margin: 20px;
}

.CarbonNeutral {
  position: relative;
  width: 100%;
}

.CarbonNeutral img {
  max-height: 300px;
}

.StoresAndApps {
  height: 95%;
  overflow-y: auto;
}

a {
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  text-decoration: none;
  opacity: 1.0;
}

.PageComponent {
  padding: 15px;
  margin: 15px;
}

.SubNav {
  width: 100%;
  position: relative;
  font-size: 12px;
  color: #FEFEFE;
}

.SubNav div {
  height: 20px;
  line-height: 20px;
}

@media only screen and (max-width: 1000px) {
  .SubNav {
    font-size: 7pt;
  }
}

.AnalyticsComponent {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: rgba(51,51,51,0.666);
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) {

}

.shadowed {
  box-shadow: 5px 5px 20px -5px #000000AA;
}

.claim {
  box-shadow: 0px 0px 20px -5px rgba(250, 220, 120, 120);
}

.AssetList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.AssetHeadline {
  height: 20%;
}

.Icon {
  position: relative;
  cursor: pointer;
}

.Icon img {
  height: 22px;
  width: 22px;
}

.UserIcon img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: auto;
  margin-top: auto;
}

.FullImageView {
  position: fixed;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 10px 10px 20px -10px #00000055;
  backdrop-filter: blur(16px);
}

.FullImageView img {
  max-height: 100%;
  max-width: 100%;
}

.CollectionImageView {
  position: fixed;
  height: fit-content;
  width: 100%;
  left: 0;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  box-shadow: none;
}

.CollectionImageView img {
  max-width: 100%;
}

.ViewAsImage {
  color: #FEFEFE;
  opacity: 0.7;
}

.ViewAsImage:hover {
  color: #FEFEFE;
  opacity: 1.0;
}

@media only screen and (max-width: 1000px) {
  .AssetList {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .UserIcon {
    font-size: 10px;
  }
}

.Page {
  height: calc(100% - 115px);
  width: 100%;
  overflow-x: hidden;
  padding-top: 10px;
  margin: auto auto auto 0;
  z-index: 1;
  transition: transform 0.2s, width 0.2s;
}

.Page.NoTransform {
  transform: none;
  margin-left: 0;
  transition: margin-left 0.2s, width 0.2s;
}

.Page.Small {
  width: calc(100% - 7.5rem);
  transform: translateX(-7.5rem);
  transition: transform 0.2s, width 0.2s;
}

.Page.Small.NoTransform {
  width: calc(100% - 7.5rem);
  margin-left: -7.5rem;
  transform: none;
  transition: margin-left 0.2s, width 0.2s;
}

.Tabs {
  position: relative;
  height: calc(100% - 20px);
}

.Tabs.Banner {
  height: calc(100% - 120px);
}

.tab-content {
  height: calc(100% - 70px);
}

.TabItem {
  display: flex;
  line-height: 26px;
  height: 26px;
  overflow: hidden;
}

.TabItem.Active .Icon {
  opacity: 1;
}

.TabItem .Icon {
  margin-right: 5px;
}

.ScrollUpIcon {
  position: absolute;
  left: calc(100% - 70px);
  top: calc(100% - 70px);
}

.ScrollUpIcon img {
  width: 40px;
  height: 40px;
}

.BackgroundImage {
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 0 10% 20% 10%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.9;
  z-index: -1;
  filter: blur(4px) brightness(0.4);
}

body .MuiPaper-root {
  font-family: sans-serif;
  background-color: #111;
  color: #FEFEFE;
  font-size: 12px;
}

.CollectionDropdownImage img {
  height: 30px;
  width: 30px;
}

body .MuiAutocomplete-option {
    cursor: pointer;
    display: flex;
    outline: 0;
    box-sizing: border-box;
    min-height: 30px;
    align-items: center;
    margin: 5px;
    padding: 0;
    justify-content: flex-start;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1450px) {
  .CollectionElement Dropdown-placeholder is-selected {
      width: 200px;
  }

  .TopMarkets .Dropdown-control {
      width: 40%;
  }

  .TopMarkets .Dropdown-menu {
      width: 40%;
  }

  .TopCollections .Dropdown-control {
      width: 40%;
  }

  .TopCollections .Dropdown-menu {
      width: 40%;
  }
}


@media only screen and (max-width: 1000px) {
  .FrontItem1,.FrontItem2 {
    height: fit-content;
  }
}

@media only screen and (min-width: 1900px) {
  .TeamMember {
    display: flex;
    width: 30%;
    height: calc(50% - 5rem);
    margin: 2rem auto;
    color: white;
    text-align: left;
    font-size: 1.2rem;
  }

  .TeamMember table {
    font-size: 1rem;
  }

  .TeamMember img {
    max-height: 200px;
  }
}

.Signature img {
  width: 50%;
}

.MarketChart .highcharts-legend {
  overflow-y: auto;
  max-height: 50px;
  height: 100px;
}

.RefreshIcon {
  width: 32px;
  height: 32px;
}

.RefreshIcon.Loading {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  margin-right: auto;
  margin-left: auto;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(-360deg);
    }
}

.App-footer {
  background-color: #F6C30D;
  color: #FEFEFE;
  height: 5%;
  z-index: 0;
}
tem1 {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 156px);
  justify-content: center;
  background-image: linear-gradient(to bottom, #00000000, #1A395266);
}

.FrontItem2 {
  display: flex;
  height: 100%;
  justify-content: center;
  background-image: linear-gradient(to bottom, #00000000, #F6C30D22);
}

.TeamList {
  margin: auto;
  text-align: center;
  height: 100%;
}

.TeamMember {
  display: flex;
  width: 30%;
  height: calc(50% - 5rem);
  margin: 2rem auto;
  color: white;
  text-align: left;
  font-size: 1rem;
}

.ServerList {
  display: flex;
  width: 20rem;
  height: 8rem;
  color: white;
  text-align: left;
  font-size: 1rem;
}

.MarketLink {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: #FEFEFE;
}

.MemberDetails {
  position: relative;
  height: 100%;
}

.TeamMember img {
  max-height: 150px;
  height: fit-content;
  border-radius: 1rem;
}

.TeamMember table {
  width: 100%;
  font-size: 0.8rem;
}

.TeamMember td:first-child {
  color: #F6C30D;
  text-align: left;
}

.TeamMember td:nth-child(2) {
  color: white;
  text-align: right;
}

.TeamMember a {
  color: white;
  cursor: pointer;
}

.MemberInfo {
  margin: 1rem;
}

.ClassBlue {
  padding: 50px;
  background-color: #1A395266;
  height: fit-content;
}

.Content {
  color: white;
  width: 50%;
  font-size: 1rem;
  margin: 1rem 25%;
  text-align: left;
}

.AssetListHeader {
  margin-top: 100px;
  margin-bottom: 20px;
  margin-left: 100px;
  font-size: 30pt;
  text-align: left;
  color: white;
  display: flex;
}

.AssetListHeader a {
  color: white;
  cursor: pointer;
}

.FrontItem2 a, .FrontItem1 a {
  color: #4a82af;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;

  color: #F6C30D;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
  width: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F6C30D;
  height: 50px;
  width: 50px;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: "SFCompact";
  src: url("/fonts/SF-Compact-Rounded-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SFCompactBold";
  src: url("/fonts/SF-Compact-Rounded-Bold.otf") format("opentype");
  font-weight: bold;
}

body {
  background-image: url('/background.svg');
  font-family: "SFCompact",sans-serif;
  background-color: #000;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body:before {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

body:after {
    z-index: 1;
    backdrop-filter: blur(20);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .App {
    width: 100%;
    left: 0;
  }
}

.AppLink {
  display: block;
  margin: 20px;
}

.StoresAndApps {
  height: 95%;
  overflow-y: auto;
}

a {
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  text-decoration: none;
  opacity: 1.0;
}

.PageComponent {
  padding: 15px;
  margin: 15px;
}

.SubNav {
  width: 100%;
  position: relative;
  font-size: 12px;
  color: #FEFEFE;
}

.SubNav div {
  height: 20px;
  line-height: 20px;
}

@media only screen and (max-width: 1000px) {
  .SubNav {
    font-size: 7pt;
  }
}

.AnalyticsComponent {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: rgba(51,51,51,0.666);
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) {

}

.shadowed {
  box-shadow: 5px 5px 20px -5px #000000AA;
}

.claim {
  box-shadow: 0px 0px 20px -5px rgba(250, 220, 120, 120);
}

.AssetList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.AssetHeadline {
  height: 20%;
}

.Icon {
  position: relative;
  cursor: pointer;
}

.Icon img {
  height: 22px;
  width: 22px;
}

.UserIcon img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: auto;
  margin-top: auto;
}

.FullImageView {
  position: fixed;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 10px 10px 20px -10px #00000055;
  backdrop-filter: blur(16px);
}

.FullImageView img {
  max-height: 100%;
  max-width: 100%;
}

.CollectionImageView {
  position: fixed;
  height: fit-content;
  width: 100%;
  left: 0;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  box-shadow: none;
}

.CollectionImageView img {
  max-width: 100%;
}

.ViewAsImage {
  color: #FEFEFE;
  opacity: 0.7;
}

.ViewAsImage:hover {
  color: #FEFEFE;
  opacity: 1.0;
}

@media only screen and (max-width: 1000px) {
  .AssetList {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .UserIcon {
    font-size: 10px;
  }
}

.Page.NoTransform {
  transform: none;
  margin-left: 0;
  transition: margin-left 0.2s, width 0.2s;
}

.Page.Small {
  width: calc(100% - 7.5rem);
  transform: translateX(-7.5rem);
  transition: transform 0.2s, width 0.2s;
}

.Page.Small.NoTransform {
  width: calc(100% - 7.5rem);
  margin-left: -7.5rem;
  transform: none;
  transition: margin-left 0.2s, width 0.2s;
}

.Tabs {
  position: relative;
  height: calc(100% - 20px);
}

.Tabs.Banner {
  height: calc(100% - 120px);
}

.tab-content {
  height: calc(100% - 70px);
}

.TabItem {
  display: flex;
  line-height: 26px;
  height: 26px;
  overflow: hidden;
}

.TabItem.Active .Icon {
  opacity: 1;
}

.TabItem .Icon {
  margin-right: 5px;
}

.ScrollUpIcon {
  position: absolute;
  left: calc(100% - 70px);
  top: calc(100% - 70px);
}

.ScrollUpIcon img {
  width: 40px;
  height: 40px;
}

.BackgroundImage {
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 0 10% 20% 10%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.9;
  z-index: -1;
  filter: blur(4px) brightness(0.4);
}

body .MuiPaper-root {
  font-family: sans-serif;
  background-color: #111;
  color: #FEFEFE;
  font-size: 12px;
}

.CollectionDropdownImage img {
  height: 30px;
  width: 30px;
}

body .MuiAutocomplete-option {
    cursor: pointer;
    display: flex;
    outline: 0;
    box-sizing: border-box;
    min-height: 30px;
    align-items: center;
    margin: 5px;
    padding: 0;
    justify-content: flex-start;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1450px) {
  .CollectionElement Dropdown-placeholder is-selected {
      width: 200px;
  }

  .TopMarkets .Dropdown-control {
      width: 40%;
  }

  .TopMarkets .Dropdown-menu {
      width: 40%;
  }

  .TopCollections .Dropdown-control {
      width: 40%;
  }

  .TopCollections .Dropdown-menu {
      width: 40%;
  }
}


@media only screen and (max-width: 1000px) {
  .FrontItem1,.FrontItem2 {
    height: fit-content;
  }
}

@media only screen and (min-width: 1900px) {
  .TeamMember {
    display: flex;
    width: 30%;
    height: calc(50% - 5rem);
    margin: 2rem auto;
    color: white;
    text-align: left;
    font-size: 1.2rem;
  }

  .TeamMember table {
    font-size: 1rem;
  }

  .TeamMember img {
    max-height: 200px;
  }
}

.Signature img {
  width: 50%;
}

.MarketChart .highcharts-legend {
  overflow-y: auto;
  max-height: 50px;
  height: 100px;
}

.RefreshIcon {
  width: 32px;
  height: 32px;
}

.NextLink {
  cursor: pointer;
}

.ProjectContent {
  color: white;
  margin: 10% auto;
  width: calc(70% - 12.6rem);
  display: block;
  text-align: left;
  justify-content: center;
}

.ProjectHeader {
  margin: 1rem 0;
}

.ProjectContent div {
  margin: auto;
}

.ProjectContent img {
  max-width: 50%;
}

.ProjectContent a {
  color: #4a82af;
}

.RefreshIcon.Loading {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  margin-right: auto;
  margin-left: auto;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(-360deg);
    }
}

.App-footer {
  background-color: #F6C30D;
  color: #FEFEFE;
  height: 5%;
  z-index: 0;
}
