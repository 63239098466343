.Footer {
  position: absolute;
  top: calc(100% - 25px);
  width: 100%;
  height: 24px;
  line-height: 24px;
  display: flex;
  color: #F6C30D;
  background-color: #1A395266;
  font-size: 12px;
  justify-content: end;
  border-top: 1px solid #F6C30D22;
  margin-top: auto;
  margin-bottom: auto;
}

.Footer img {
  max-height: 22px;
  margin: auto;
}

.Footer .DiscordLink img {
  height: 20px;
}

.TelegramLink {
  width: 22px;
  height: 22px;
  position: relative;
  top: -1px;
}

.TwitterLink {
  width: 22px;
  height: 22px;
  position: relative;
  top: -3px;
}

.Footer a {
  text-decoration: none;
  color: #F6C30D;
  position: relative;
  line-height: 24px;
  display: flex;
}

.ImprintLink {
  display: flex;
  margin-right: 4rem;
  justify-content: end;
}

.ImprintContent {
  color: white;
  margin: 10% auto;
  width: calc(50% - 12.6rem);
  display: block;
  text-align: left;
  justify-content: center;
}

.ImprintHeader {
  margin: 1rem 0;
}

.ImprintContent div {
  margin: auto;
}

.ImprintContent a {
  color: #4a82af;
}

@media only screen and (max-width: 1000px) {
  .Footer {
    display: none;
  }
}
